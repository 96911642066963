import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosArrowForward } from "react-icons/io";
import { cutOffAtWord } from "../util/string";

const BlogCard = ({
	title,
	image,
	uri,
	excerpt,
	imageAlt,
	textSize,
	showArrow = true,
	isLarge = false,
	isSearch = false,
}) => {
	const cleanContent = (content) => {
		if (typeof window !== "undefined") {
			const parser = new DOMParser();
			const doc = parser.parseFromString(content, "text/html");
			const paragraphs = doc.querySelectorAll("p");
			let cleanString = "";

			for (let i = 0; i < paragraphs.length && cleanString.length < 150; i++) {
				const textContent = paragraphs[i].textContent || "";
				cleanString += textContent
					.replace(/&nbsp;/g, " ")
					.replace(/[\s\t]+/g, " ")
					.trim();
				if (cleanString.length < 150) {
					cleanString += " ";
				}
			}

			if (cleanString.length < 150) {
				const remainingContent = content
					.replace(/<\/?[^>]+(>|$)/g, "")
					.replace(/&nbsp;/g, " ")
					.replace(/[\s\t]+/g, " ");
				cleanString += remainingContent
					.substring(0, 160 - cleanString.length)
					.trim();
			}

			return `${cutOffAtWord(cleanString, 150).trim()}`;
		} else {
			return "";
		}
	};

	return (
		<Col
			md={isSearch ? 12 : 6}
			xl={isSearch ? 12 : isLarge ? 6 : 4}
			className={`blog-card-wrapper ${isLarge ? "large" : ""}`}
		>
			<Card className="w-100 h-100 border-0">
				{image && (
					<Link to={uri}>
						<div className="blog-card-image position-relative mb-3">
							<Card.Img
								as={GatsbyImage}
								variant="top"
								image={image}
								alt={imageAlt}
								className="rounded-0"
							/>
						</div>
					</Link>
				)}

				<Card.Body className="p-0 d-flex flex-column">
					<Link to={uri} className="title-link">
						<h3
							style={{
								minHeight: "3em",
							}}
							className={`d-flex mb-3 text-capitalize lh-base ${textSize}`}
						>
							{title}
						</h3>
					</Link>
					{excerpt && (
						<div className="mb-0">
							<p>{cleanContent(excerpt)}</p>
						</div>
					)}

					<Col xs={12} className="d-flex justify-content-start">
						<Button
							size="vSmall"
							className=" daxPro-regular border-0 p-0 mt-0"
							as={Link}
							to={uri}
							variant="plain"
						>
							Read More{" "}
							{showArrow && (
								<IoIosArrowForward
									className="text-secondary"
									style={{ fontSize: "16px" }}
								/>
							)}
						</Button>
					</Col>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default BlogCard;
